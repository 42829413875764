import {
  ANALYTICS_REPORT_LOADING,
  ANALYTICS_REPORT_LOADED,
  SET_VDPS_DATA,
  SET_FILTER_VALUES,
  EMPTY_FILTER_VALUES
} from './Types';

const initialState = {
  analyticsLoading: false,
  totalVdps: 0,
  vdpsPerDay: 0,
  filterValues: {}
}

const reducer = (state=initialState, action) => {
  switch(action.type) {
    case ANALYTICS_REPORT_LOADING:
      return {
        ...state,
        analyticsLoading: true
      }

    case ANALYTICS_REPORT_LOADED:
      return {
        ...state,
        analyticsLoading: false
      }

    case SET_VDPS_DATA:
      return {
        ...state,
        totalVdps: action.payload.totalVdps,
        vdpsPerDay: action.payload.vdpsPerDay
      }

    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: {...state.filterValues, ...action.payload.filterValues}
      }

    case EMPTY_FILTER_VALUES:
      return {
        ...state,
        filterValues: {}
      }

    default:
      return state
  }
}

export default reducer
