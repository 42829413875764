import React, { Fragment, useEffect, useState } from "react"
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,

} from "@react-pdf/renderer"
import wheelerAdvLogo from '../library/images/logo-login.png'
import wheelerAdvFooter from '../library/images/footer.png'

// import { titleCase, formatDate } from './index'

const styles = StyleSheet.create({
  body: {
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    display:"flex",
    flexDirection:"column"
  
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 30,
    height:"60vh"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: "33.33%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 10
  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 10
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    fontSize: 10
  },
  topTableHeader: {
    // backgroundColor: "#F7F7F7",
    color: "#688a7e",
    fontWeight: "bolder",
    fontSize: 15,
    // height: "50%",
    textAlign: "left",
    width: "100%",
    borderStyle: "solid",
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0
  },
  headingTop: {
    color: "#000",
    fontSize: 25,
    textAlign: "center",
    width: "100%",
    marginBottom: 35
  },
  companyHeader: {
    width: "100%",
    textAlign: "left",
    marginBottom: 15,
  },
  headerLogo: {
    width: "20vh",
    height: "10vh"
  },
  companyFooter: {
    width: "100%",
    // textAlign: "center",
    marginTop:"auto",
    bottom:0
  },
  footerImage: {
    width: "68vh",
    height: "11vh"
  }
})
    // display: "block",
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "50%",
    // marginTop: 15,

export function PdfDocument(props) {
  const { data, selected, dealername } = props
  const [selectedEntries, setSelectedEntries] = useState([])
  useEffect(()=>{
    setSelectedEntries(selected)
  }, [selected])
  const datalist = data && Object.keys(data).length ? Object.keys(data).filter(
    (comp, index) => selected.indexOf(index) > -1) : []
  return (
    <Document>
     
          {datalist && datalist.length ? datalist.map((comp, index) =>
           <Page style={styles.body}>
           <View style={styles.companyHeader}>
             <Text>
               <Image src={wheelerAdvLogo} style={styles.headerLogo} />
             </Text>
           </View>
           <View style={styles.headingTop}>
             <Text>{dealername}</Text>
           </View>
           <View>
            <View wrap={false} style={styles.table} key={index}>
              <View style={styles.topTableHeader}>
                <Text style={styles.tableCellHeader}>{ comp }</Text>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Competitor Name</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>MSRP</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Sales Price</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Vehicles Count</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Notes</Text>
                </View>
              </View>
              {data[comp].map((item, ix) => {
                let itemSalesPrice = `$${item.sales_price}`
                let itemMSRP = `$${item.msrp}`
                if (item.sales_price === 0 || !item.sales_price) {
                  item.price_history_sales_price.every(p => {
                    if (p.sales_price) {
                      itemSalesPrice = `$${p.sales_price}`
                      return false
                    }
                    itemSalesPrice = p.sales_price
                    return true
                  })
                }
                if (item.msrp === 0 || !item.msrp) {
                  item.price_history_msrp.every(p => {
                    if (p.msrp) {
                      itemMSRP = `$${p.msrp}`
                      return false
                    }
                    itemMSRP = p.msrp
                    return true
                  })
                }
                if(item.vehicles_count === 0){
                  item.vehicles_count = `Not available`
                }
                if(!itemSalesPrice || itemSalesPrice === '$0'){
                  itemSalesPrice = `Not available`
                }
                if(!itemMSRP || itemMSRP === '$0'){
                  itemMSRP = `Not available`
                }
                return (<View style={styles.tableRow} key={ix}>
                  <Fragment>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.name}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{itemMSRP}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{itemSalesPrice}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.vehicles_count}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.notes}</Text>
                    </View>
                  </Fragment>
                </View>)
              })}
            </View>
            </View>
        <View style={styles.companyFooter}>
          <Text>
            <Image src={wheelerAdvFooter} style={styles.footerImage} />
          </Text>
        </View>
      </Page>
          ) : ""}
      
    </Document>
  );
}

export default PdfDocument
