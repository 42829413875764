export const groupKeys = (input, key) => {
  let group = input.reduce((r, a) => {
   r[a[key]] = [...r[a[key]] || [], a];
   return r;
  }, {});
  return group
}

export const getSearchResults = (values, query) => {
  query = query.toLowerCase().trim()
  return values.filter(item => {
    const rChars = item.report_title.trim().toLowerCase().split(' ')
    const cRes = rChars.filter(char => {
      return char.includes(query.trim()) ||  // .slice(0, query.length)
        char.includes(query.trim())  // .slice(0, query.length)
    })

    const rfRes = rChars.filter(char => {
      const qs = query.trim().split(' ')
      const df = qs.filter(q => {
        return char.includes(q)
      })
      return df.length
    })

    const dChars = item.dealer_name.trim().toLowerCase().split(' ')
    const dRes = dChars.filter(char => {
      return char.includes(query.trim()) ||  // .slice(0, query.length)
        char.includes(query.trim())  // .slice(0, query.length)
    })

    const dfRes = dChars.filter(char => {
      const qs = query.trim().split(' ')
      const df = qs.filter(q => {
        return char.includes(q)
      })
      return df.length
    })

    return cRes.length || dRes.length || dfRes.length || rfRes.length
  })
}

  // return values.filter(item => item.report_title.trim().toLowerCase().slice(0, query.length) === query ||
  //   item.dealer_name.toLowerCase().slice(0, query.length) === query
  // )

export const getCompetitiveSearchResults = (values, query) => {
  query = query.toLowerCase().trim()
  return values.filter(item => {
    const chars = item.model.trim().toLowerCase().split(' ')
    const res = chars.filter(char => {
      return char.slice(0, query.length) === query
    })

    const mfRes = chars.filter(char => {
      const qs = query.trim().split(' ')
      const df = qs.filter(q => {
        return char.includes(q)
      })
      return df.length
    })

    return res.length || mfRes.length
  })
}


export const getDealerSearchResults = (values, query) => {
  query = query.toLowerCase().trim()
  // values.filter(item => item.name.trim().toLowerCase().slice(0, query.length) === query)
  return values.filter(item => {
    const chars = item.name.trim().toLowerCase().split(' ')
    const res = chars.filter(char => {
      return char.slice(0, query.length) === query
    })

    const mfRes = chars.filter(char => {
      const qs = query.trim().split(' ')
      const df = qs.filter(q => {
        return char.includes(q)
      })
      return df.length
    })

    return res.length || mfRes.length
  })
}

export const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
   splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const replaceUnderscores = str => {
  return str.replace(/_/g, " ")
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
