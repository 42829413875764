import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const signUp = (values) => {
  return axios.post(`${API_URL}/users/registration/`, values)
}

export const verifyEmail = (values) => {
  return axios.post(`${API_URL}/users/verify/`, values)
}

export const forgotEmail = (values) => {
  return axios.post(`${API_URL}/users/forgot-password/`,values)
}

export const getOpenDealers = () => {
  return axios.get(`${API_URL}/core/dealer/open-list/`)
}

export const getUserPermissions = () => {
  return axios.get(`${API_URL}/users/user-permissions/`)
}

