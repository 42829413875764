import { SAVE_DEALER } from "./constants"

const dealerAction = (data) => {
    return{
        type: SAVE_DEALER,
        payload: data
    }
}


export { dealerAction }