import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import 'bootstrap/dist/css/bootstrap.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'antd/dist/antd.css';
import './index.css';
// import './App.css'
import * as serviceWorker from './serviceWorker'
import Routes from './routes'
import IndexReducer from './reducers'
import { history, setAuthHeader } from './_helpers'
import { setUser } from './apps/auth/actions'

// /*eslint-disable */
// const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
// /*eslint-enable */

const store = createStore(
  IndexReducer,
  compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)

store.replaceReducer(IndexReducer)

if(sessionStorage.getItem('user')) {
  setAuthHeader()
  store.dispatch(
    setUser(JSON.parse(sessionStorage.getItem('user')))
  )
}

ReactDOM.render(
  // calls some functions twice in StrictMode
  // <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
