import {
  ADD_COMPETITIVE_INTELLIGENCE,
  SET_CI_DEALER
} from './constants'

const initialState = {
  newCompetitiveIntelligences: [],
  CIdealer : null,
}

const reducer = (state=initialState, action) => {
  switch (action.type) {

    // check for newly created cometitive intelligence objects
    case ADD_COMPETITIVE_INTELLIGENCE:
      return {
        ...state,
        newCompetitiveIntelligences: action.newCompetitiveIntelligences
      }
    case SET_CI_DEALER:
      return{
        ...state,
        CIdealer:action.payload
      }

    default:
      return state
  }
}

export default reducer
