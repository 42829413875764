import axios from "axios";

import {
  CLIENT_SET,
  CLIENT_UNSET,
  LOGIN_ERROR,
  SET_IP,
  USER_PERMISSIONS,
} from "./constants";
import { getUserPermissions } from "./apis";

export function setUser(user) {
  return {
    type: CLIENT_SET,
    user,
  };
}

export function setPermissions(permissions) {
  return {
    type: USER_PERMISSIONS,
    permissions,
  };
}

export function unsetUser() {
  return {
    type: CLIENT_UNSET,
  };
}

export function setIpAction(ip) {
  return { type: SET_IP, ip };
}

export function setIp(ip) {
  // return dispatch=>{
  //   dispatch(setIpAction(ip))
  // }
  // return { type: SET_IP, ip }
  localStorage.setItem("IP", ip);
}

export function getPermissions() {
  return (dispatch) => {
    getUserPermissions()
      .then(({ data }) => {
        dispatch(setPermissions(data.result));
      })
      .catch(({ data }) => {
        console.log(data);
      });
  };
}

export const login = (loginInput) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/login/`, loginInput)
      .then(({ data }) => {
        console.log(data);
        sessionStorage.setItem("user", JSON.stringify(data.result));
        dispatch(setUser(data.result));
        window.location = "/product/dashboard";
      })
      .catch((err) => {
        dispatch({ type: LOGIN_ERROR, error: err.response.data.message });
      });
  };
};

export const logout = () => {
  sessionStorage.removeItem("user");
  window.location = "/auth/login";
  // window.location.reload();
  return (dispatch) => {
    dispatch(unsetUser());
  };
};
