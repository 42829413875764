import { combineReducers } from 'redux'
import authReducer from './apps/auth/reducer'
import productReducer from './apps/product/reducer'
import reportReducer from './apps/reporting/reducer'
import analyticsReducer from './apps/analytics/reducer'
import DealerReducer from './layout/DealerReducer'

export default combineReducers({
    authReducer, productReducer, reportReducer, analyticsReducer, DealerReducer
})
