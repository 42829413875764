import React, { Component, Suspense, lazy, useRef } from "react";
import IdleTimer from "react-idle-timer";
import { Route, Switch, Link, Redirect } from "react-router-dom";

import AppRoute from "./appRoute";
import { logout } from "./apps/auth/actions";
import PrivateRoute from "./PrivateRoute";
// import ErrorBoundary from './_helpers/ErrorBoundary'

// import Home from './apps/home/Home'

const AuthRoutes = lazy(() => import("./apps/auth/routes"));
const ProductRoutes = lazy(() => import("./apps/product/routes"));
const UserRoutes = lazy(() => import("./apps/user/routes"));
const AnalyticsRoutes = lazy(() => import("./apps/analytics/routes"));
const ReportingRoutes = lazy(() => import("./apps/reporting/routes"));
const ComparitiveAnalysisRoutes = lazy(() =>
  import("./apps/comparativeAnalysis/routes")
);

const NewAnalysisRoutes = lazy(() => import("./apps/newAnalysis/routes"));

class Routes extends Component {
  // idelRef = useRef()
  IdelHandler = () => {
    logout();
  };
  render() {
    return (
      // <ErrorBoundary>
      <Suspense
        fallback={
          <div
            className="spinner-border suspense-fallback-loader"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        }
      >
        <IdleTimer
          // ref={this.idelRef}
          timeout={3600 * 1000}
          onIdle={this.IdelHandler}
        >
          <Switch>
            <Redirect exact from="/" to="/auth/login" />
            <Route path="/auth" component={AuthRoutes} />
            {/* <Layout> */}
            <Redirect from="/home/:wlid?" to="/auth/login/:wlid?" />
            <AppRoute path="/users" component={UserRoutes} />
            <AppRoute path="/product" component={ProductRoutes} />
            <AppRoute path="/analytics" component={AnalyticsRoutes} />
            <AppRoute path="/reporting" component={ReportingRoutes} />
            <PrivateRoute
              path="/comparitive-analysis"
              component={ComparitiveAnalysisRoutes}
            />
            <PrivateRoute path="/new-analysis" component={NewAnalysisRoutes} />
            {/* </Layout> */}
            <Route path="*" component={NoMatch} />
          </Switch>
        </IdleTimer>
      </Suspense>
      // </ErrorBoundary>
    );
  }
}

function NoMatch() {
  // let location = useLocation();

  return (
    <div className="page-404">
      <p className="text-404">404</p>
      <h2>Aww Snap!</h2>
      <p>
        Something went wrong or that page doesn’t exist yet. <br />
        <Link to="/product/dashboard/">Return Home</Link>
      </p>
    </div>
  );
}
export default Routes;
