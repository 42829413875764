import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// White label APIs

export const checkWhiteLabel = () => {
  return axios.get(`${API_URL}/core/whitelabel/check-status/`);
};

export const getWhiteLabel = (id = "") => {
  return axios.get(`${API_URL}/core/whitelabel/${id}`);
};

// if there is no image don't use formdata
export const whiteLabelEdit = (values) => {
  if (
    typeof values.product_logo === "string" ||
    values.product_logo instanceof String
  ) {
    delete values.product_logo;
    return axios.patch(`${API_URL}/core/whitelabel/${values.id}/`, values);
  } else {
    var formData = new FormData();
    Object.keys(values).map((key) => {
      if (key === "margin_costs") {
        // values[key] = JSON.stringify(values[key])
        for (let i = 0; i < values[key].length; i++) {
          formData.append(
            `margin_costs[${i}].margin_cost`,
            values[key][i].margin_cost
          );
          formData.append(`margin_costs[${i}].report`, values[key][i].report);
        }
      } else {
        formData.append(key, values[key]);
      }
      return key;
    });
    return axios.patch(`${API_URL}/core/whitelabel/${values.id}/`, formData, {
      // headers: {
      // 'Content-Type': false,  // 'multipart/form-data',
      // 'processData': false,
      // 'Access-Control-Allow-Origin': 'http://localhost:3000',
      // 'crossDomain' : true,
      // },
      // withCredentials: true,
    });
  }
};

export const getWhiteLabelList = () => {
  return axios.get(`${API_URL}/core/whitelabel/`);
};

export const whiteLabelDelete = (id) => {
  return axios.delete(`${API_URL}/core/whitelabel/${id}/`);
};

export const whiteLabelChangeActive = (id) => {
  return axios.put(`${API_URL}/core/whitelabel/${id}/change_active/`);
};

export const getReports = () => {
  return axios.get(`${API_URL}/core/report/list/`);
};

//margincost APis
export const setMarginCost = (values) => {
  return axios.post(`${API_URL}/core/whitelabel/change-margin/`, values);
};

export const setDealerMarginCost = (values) => {
  return axios.post(`${API_URL}/core/dealer/change-margin/`, values);
};

// WhitelabelRequest APIs

export const whiteLabelRequestCreate = (values) => {
  return axios.post(`${API_URL}/core/whitelabel-request/`, values);
};

export const getWhiteLabelRequest = (id = "") => {
  let url = `${API_URL}/core/whitelabel-request/`;
  if (id) url = `${url}${id}/`;

  return axios.get(url);
};

export const getWhiteLabelRequestList = (id) => {
  return axios.get(`${API_URL}/core/whitelabel-request/`);
};

export const whiteLabelRequestEdit = (values) => {
  return axios.put(`${API_URL}/core/whitelabel-request/${values.id}/`, values);
};

export const whiteLabelRequestApprove = (id) => {
  return axios.post(`${API_URL}/core/whitelabel-request/${id}/approve/`);
};

export const whiteLabelRequestChangeStatus = (values) => {
  return axios.post(
    `${API_URL}/core/whitelabel-request/${values.id}/change_status/`,
    values
  );
};

export const getDashboard = () => {
  return axios.get(`${API_URL}/core/dashboard/`);
};

export const whiteLabelCreate = (values) => {
  var formData = new FormData();
  Object.keys(values).forEach((key) => {
    formData.append(key, values[key]);
  });
  return axios.post(`${API_URL}/core/whitelabel/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Model names API

export const getModelNames = () => {
  return axios.get(`${API_URL}/core/model/list/`);
};

// Competitive analysis APIs

export const createCompetitiveAnalysis = (values) => {
  return axios.post(`${API_URL}/core/competitive-analysis/`, values);
};

export const getAllFiltersValue = () => {
  return axios.get(`${API_URL}/core/competitive-analysis/`);
};


// for manually ci
export const getCompetitiveIntelligence = (params) => {
  return axios.get(`${API_URL}/core/competitive-analysis/`, {
    params,
  });
};

// for rv_trader
export const getRVTrader = (params) => {
  return axios.get(`${API_URL}/core/competitive-analysis/`, {
    params,
  });
};

// for get filter
export const getCIFilter = (params) => {
  return axios.get(`${API_URL}/core/competitive-intelligence/`, { params });
};

// export const getCompetitiveIntelligence = (params) => {
//   return axios.get(`${API_URL}/core/competitive-analysis/ci_list/`, {
//     params,
//   });
// };

export const retriveCompetitiveIntellige = (id) => {
  return axios.get(
    `${API_URL}/core/competitive-analysis/${id}/?name=RV_Trader`
  );
};

export const deleteCompetitiveIntellige = (id) => {
  return axios.delete(
    `${API_URL}/core/competitive-analysis/${id}/?name=RV_Trader`
  );
};

export const createRVTrader = (params) => {
  return axios.get(`${API_URL}/core/competitive-analysis/rv_trader`, {
    params,
  });
};

// export const updateRVTrader = (params,id) => {
//   return axios.get(`${API_URL}/core/competitive-analysis/rv_trader/${id}/`, {
//     params,
//   });
// };

export const updateRVTrader = (values, id) => {
  return axios.patch(
    `${API_URL}/core/competitive-analysis/${id}/?name=RV_Trader`,
    values
  );
};

export const updateCompetitiveIntelligence = (values, id) => {
  return axios.patch(`${API_URL}/core/competitive-intelligence/${id}/`, values);
};

export const updateCompetitiveAnalysis = (values, id) => {
  return axios.patch(`${API_URL}/core/competitive-analysis/${id}/`, values);
};

export const retrieveCompetitiveAnalytics = (id) => {
  return axios.get(`${API_URL}/core/competitive-analysis/${id}/`);
};

export const deleteCompetitiveAnalytics = (id) => {
  return axios.delete(`${API_URL}/core/competitive-analysis/${id}/`);
};

export const addUserCompetitive = (values) => {
  return axios.post(
    `${API_URL}/core/competitive-analysis/add_to_users/`,
    values
  );
};

export const updateCompetitivePrice = (id) => {
  return axios.get(`${API_URL}/core/competitive-analysis/${id}/update_price/`);
};

export const getPageSize = (params) => {
  return axios.get(`${API_URL}/core/competitive-analysis/get_page_size/`, {
    params,
  });
};

// Send email

export const sendMail = (values, params) => {
  return axios.post(`${API_URL}/core/send-email/`, values, {
    params,
  });
};

// Dealers APIs

export const createDealer = (values) => {
  return axios.post(`${API_URL}/core/dealer/`, values);
};

export const getDealers = (params) => {
  return axios.get(`${API_URL}/core/dealer/`, {
    params,
  });
};

export const updateDealer = (values, id) => {
  return axios.patch(`${API_URL}/core/dealer/${id}/`, values);
};

export const retrieveDealer = (id, params) => {
  return axios.get(`${API_URL}/core/dealer/${id}/`, {
    params,
  });
};

export const deleteDealer = (id) => {
  return axios.delete(`${API_URL}/core/dealer/${id}/`);
};

// Locations API

export const getLocations = (location, params) => {
  return axios.get(`${API_URL}/core/${location}/list/`, { params });
};

//CI Table apis

export const getCIDashboard = () => {
  return axios.get(
    `${API_URL}/core/competitive-analysis/competitive_analytics_dashboard/`
  );
};

export const getMakeModel = (ids) => {
  return axios.get(
    `${API_URL}/core/competitive-analysis/make_model_distribution/?cis=${ids}`
  );
};

export const getCompetitorPrices = (ids) => {
  return axios.get(
    `${API_URL}/core/competitive-analysis/competitor_prices/?cis=${ids}`
  );
};

export const getDealerInventory = (params) => {
  // console.log(ids,"s")
  return axios.get(`${API_URL}/core/dealer-inventory`, {
    params,
  });
};

export const getReportDownload = () => {
  return axios.get(`${API_URL}/core/ci-non-working-urls/`);
};

export const setLogs = (values) => {
  return axios.post(`${API_URL}/users/logs/`, values);
};

export const getLogs = () => {
  return axios.get(`${API_URL}/users/logs/`);
};
