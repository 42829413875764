const formatDate = (date, timeZone='America/Panama') => {
  // const dateTimeFormat = new Intl.DateTimeFormat('en', {
  //   day: '2-digit', month: 'short', year: 'numeric',
  //   hour: 'numeric', minute: 'numeric'
  // })
  // const [
  //   { value: day },, { value: month },, { value: year },,
  //   { value: hour },, { value: minute }
  // ] = dateTimeFormat.formatToParts(date ?
  //   new Date(date)
  // : new Date())
  // return `${day} ${month} ${year}, ${hour}:${minute}`
  let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }  // weekday: 'long',
  options.timeZone = timeZone
  options.timeZoneName = 'short'
  return new Date(date).toLocaleString('en-US', options)  // , timeZoneName: 'long'
}

const shortFormatDate = `${new Date().getFullYear()}-${("0" + (new Date().getMonth())).slice(-2)}-${new Date().getDate()}`
const minCalendarDate = `${new Date().getFullYear()}-${("0" + (new Date().getMonth())).slice(-2)}-${new Date().getDate()}`
const maxCalendarDate = `${new Date().getFullYear()}-${("0" + (new Date().getMonth() + 1)).slice(-2)}-${new Date().getDate()}`

export { formatDate, shortFormatDate, minCalendarDate, maxCalendarDate }
