import React from 'react'
import { Route } from 'react-router-dom'
import { Layout } from './layout'

const AppRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => (
				<Layout>
					<Component {...props} />
				</Layout>
			)}
		/>
	)
}

export default AppRoute