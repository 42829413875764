import { REPORT_LOADING, REPORT_LOADED, REMOVE_PLOT,
  ADD_PLOT, REMOVE_NEW_PLOT, ADD_NEW_PLOT } from './Types';

const initialState = {
  reportLoading: false,
  removePlots: [],  // new Set(),
  errors: [],
  newPlots: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case REPORT_LOADING:
      return {
        ...state,
        reportLoading: true,
      }

    case REPORT_LOADED:
      return {
        ...state,
        reportLoading: false
      }

    case REMOVE_PLOT:
      // let removePlots = state.removePlots
      // console.log(removePlots)
      // removePlots.add(action.payload)

      let removePlots = state.removePlots
      // add to removeplots for hiding the plot div
      // by checking if it already doesn't exists
      if(removePlots.indexOf(action.payload) === -1) {
          removePlots.push(action.payload);
      }
      return {
        ...state,
        removePlots
      }

    case ADD_PLOT:
      let addPlots = state.removePlots
      const index = addPlots.indexOf(action.payload)
      let newPlots = state.newPlots
      // if plot exists in removePlots then remove it
      // and add in the new plots list
      if (index > -1) newPlots.push(...addPlots.splice(index, 1))
      return {
        ...state,
        removePlots: addPlots,
        // newPlots
      }

    case REMOVE_NEW_PLOT:
      let newPlotsDiff = state.newPlots
      const newPlotIndex = newPlotsDiff.indexOf(action.payload)
      // if plot exists in new plots then remove it
      if (newPlotIndex > -1) newPlotsDiff.splice(newPlotIndex, 1)
      return {
        ...state,
        newPlots: newPlotsDiff
      }

    case ADD_NEW_PLOT:
      let addNewPlots = state.newPlots
      const addNewPlotIndex = addNewPlots.indexOf(action.payload)
      // add all the plots whose metrics is currently selected
      if (addNewPlotIndex === -1) addNewPlots.push(action.payload)
      return {
        ...state,
        newPlots: addNewPlots
      }

    default:
      return state
  }
}

export default reducer
