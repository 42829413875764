export default function (dateObj, format='YYYYMMDD') {
  if (dateObj.format !== undefined) {
    if (format === 'date') {
      dateObj = dateObj.toDate()
    } else {
      dateObj = dateObj.format(format)
    }
  } else {
    if (format === 'YYYYMMDD') {
      //+1 removed problem will arise on 1st of next month 
      //dateObj.getDate() + '1'
      dateObj = `${dateObj.getFullYear()}${("0" + (dateObj.getMonth() + 1)).slice(-2)}${("0" + (dateObj.getDate())).slice(-2)}`
    }
    if (format === 'YYYY-MM-DD') {
      dateObj = `${dateObj.getFullYear()}-${("0" + (dateObj.getMonth() + 1)).slice(-2)}-${("0" + (dateObj.getDate())).slice(-2)}`
    }
  }

  return dateObj
}
