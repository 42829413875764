import React from 'react'
import { usePromiseTracker } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";
import { Modal } from 'react-bootstrap'
import './LoadingIndicator.css'


const override = `
  display: block;
  margin: 0 auto;
`;

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker()

  if (props.show) {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={promiseInProgress}
      >
        <Modal.Body>
          <ClipLoader
            size={50}
            css={override}
            color={"#343a40"}
          />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    promiseInProgress &&
    <Modal
      className="my-modal"
      backdrop="static"
      keyboard={false}
      centered
      show={promiseInProgress}
    >
      <Modal.Body>
        <ClipLoader
          size={50}
          css={override}
          color={"#343a40"}
        />
      </Modal.Body>
    </Modal>
  );
}

export default LoadingIndicator
