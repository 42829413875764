export const CLIENT_SET = 'CLIENT_SET'
export const CLIENT_UNSET = 'CLIENT_UNSET'

export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_EXISTING = 'LOGIN_EXISTING'
export const LOGOUT = 'LOGOUT'

export const USER_PERMISSIONS = 'USER_PERMISSIONS'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const SET_IP = 'SET_IP'
