import {
  CLIENT_SET,
  CLIENT_UNSET,
  LOGIN_ERROR,
  USER_PERMISSIONS,
  UPDATE_USER_DATA,
  SET_IP,
} from './constants'

const initialState = {
  isAuthenticated: false,
  userType: '',
  user: {},
  requesting: true,
  successful: false,
  messages: [{ body: 'Logging in...', time: new Date() }],
  errors: [],
  ip:0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IP:
      return{
        ...state,
        ip:action.ip,
        user:null
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        error: action.error
      }

    // Successful?  Reset the login state.
    case CLIENT_SET:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
        requesting: false,
        successful: true,
        error: ''
      }

    // Append the error returned from our api
    // set the success and requesting flags to false
    case CLIENT_UNSET:
      return {
        ...state,
        initialState
      }
    //update redux state when user data is updated
    case UPDATE_USER_DATA:
      return{
        ...state,
        user: {
          ...state.user,
          first_name: action.userData.first_name,
          last_name: action.userData.last_name,
          username: action.userData.full_name,
          email: action.userData.email,
          dealer: action.userData.dealer_name
        }
      }

    // set user permissions in user object
    case USER_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.permissions,
        }
      }
     
    default:
      return state
  }
}

export default reducer
