const getValidPermission = (user, codeName='') =>{
	const permissions = user.permissions.filter(i => i.active)
        .map(({ codename, group }) => `${group.toLowerCase()}-${codename}`)
      let valid = true
      if (user.user_level === 3) {
          valid = permissions.includes(codeName)
      }
      if (user.user_level === 4) {
          valid = permissions.includes(codeName)
      }
      return valid
}

export default getValidPermission
