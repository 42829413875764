import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

import { maxCalendarDate } from './formatDate'

class DateRangePicker extends PureComponent {
  state = {  }

  constructor(props) {
    super(props)
    this.fromDateRef = React.createRef()
    this.toDateRef = React.createRef()
  }

  componentDidMount() {
    this.fromDateRef.current.setAttribute('max', maxCalendarDate)
    this.toDateRef.current.setAttribute('max', maxCalendarDate)
  }

  handleChange = evt => {
    const { name, value } = evt.target
    this.setState({ [name]: value })
    this.toDateRef.current.setAttribute('min', this.fromDateRef.current.value)
  }

  render() {
    return (
      <Row>
        <Col lg={6} sm={6}>
          <small className="text-muted">From Date</small>
          <input
            onChange={e => {this.props.handleChange(e); this.handleChange(e)}}
            ref={this.fromDateRef}
            className="form-control"
            type="date"
            name="fromDate"
          />
        </Col>
        <Col lg={6} sm={6}>
          <small className="text-muted">To Date</small>
          <input
            onChange={e => {this.props.handleChange(e); this.handleChange(e)}}
            ref={this.toDateRef}
            className="form-control"
            type="date"
            name="toDate"
          />
        </Col>
      </Row>
    )
  }
}

export default DateRangePicker
