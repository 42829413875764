// import facebookImage from '../library/images/facebook.png'
// import twitterImage from '../library/images/twitter.png'
// import instagramImage from '../library/images/instagram.png'
// import billboardImage from '../library/images/billboard.png'
// import googleImage from '../library/images/google.png'
// import bingImage from '../library/images/bing.png'
// import yahooImage from '../library/images/yahoo.png'
// import prerollImage from '../library/images/preroll.png'
// import youtubeImage from '../library/images/youtube.png'
// import tiktokImage from '../library/images/tiktok.png'
// import videoImage from '../library/images/video.png'
// import ctvImage from '../library/images/ctv.png'

const USER_MENU = {
  1: [
    { label: "Manage Dealers", path: "/product/dealers-list/" },
    { label: "White Label Requests", path: "/product/white-label-requests/" },
    { label: "White Labels", path: "/product/white-labels/" },
    { label: "Manage Users", path: "/users/list/" },
    // { 'label': 'End Users', 'path': '/users/list/' },
    { label: "Analytics", path: "/analytics/report/" },
    { label: "Permission requests", path: "/users/perm-requests/" },
    {
      label: "Competitive Intelligence",
      path: "/product/competitive-intelligence-list/",
    },
    // { label: "Offerings", path: "/users/offerings" },
    {
      label: "Reporting",
      subPaths: [{ label: "Ihub", path: "/reporting/ihub" }],
    },
    { label: "VDP Sale", path: "/analytics/vdpsale/" },
    { label: "Comparative Analysis", path: "/comparitive-analysis" },
    { label: "New Analysis", path: "/new-analysis" },
  ],
  2: [
    { label: "Manage Dealers", path: "/product/dealers-list/" },
    { label: "Manage Users", path: "/users/list/" },
    { label: "Analytics", path: "/analytics/report/" },
    {
      label: "Competitive Intelligence",
      path: "/product/competitive-intelligence-list/",
    },
    // { label: "Offerings", path: "/users/offerings" },
    { label: "Reporting", path: "/reporting/" },
    { label: "VDP Sale", path: "/analytics/vdpsale/" },
    { label: "Comparative Analysis", path: "/comparitive-analysis" },
    { label: "New Analysis", path: "/new-analysis" },
  ],
  3: [
    { label: "Analytics", path: "/analytics/report/" },
    // { 'label': 'Marketing', 'path': '/product/dashboard/' },
    // {
    //   label: "Competitive Intelligence",
    //   path: "/product/competitive-intelligence-list/",
    // },
    // { label: "Offerings", path: "/users/offerings" },
    { label: "Reporting", path: "/reporting/" },
    // { label: "VDP Sale", path: "/analytics/vdpsale/" },
    // { label: "Comparative Analysis", path: "/comparitive-analysis" },
    // { label: "New Analysis", path: "/new-analysis" },
    // { 'label': 'Manage Users', 'path': '/users/list/' },
  ],
  4: [
    { label: "Manage Users", path: "/users/list/" },
    { label: "Analytics", path: "/analytics/report/" },
    {
      label: "Competitive Intelligence",
      path: "/product/competitive-intelligence-list/",
    },
    // { label: "Offerings", path: "/users/offerings" },
    { label: "Reporting", path: "/reporting/" },
    { label: "VDP Sale", path: "/analytics/vdpsale/" },
    { label: "Comparative Analysis", path: "/comparitive-analysis" },
    { label: "New Analysis", path: "/new-analysis" },
  ],
};

const OFFERINGS_MENU = {
  "Social Media": {
    sub_offers: {
      Facebook: {
        icon: require("../library/images/facebook.png"),
      },
      Instagram: {
        icon: require("../library/images/instagram.png"),
      },
      Twitter: {
        icon: require("../library/images/twitter.png"),
      },
    },
    icon: "fa-share",
    link: "social media",
  },
  Banner: {
    sub_offers: {
      "Native Ads": {
        icon: require("../library/images/billboard.png"),
      },
      "Display Ads": {
        icon: require("../library/images/billboard.png"),
      },
      "Responsive Ads": {
        icon: require("../library/images/billboard.png"),
      },
    },
    icon: "fa-file",
    link: "banner",
  },
  "Paid Media": {
    sub_offers: {
      "Google Adwords": {
        icon: require("../library/images/google.png"),
      },
      "Bing Ads": {
        icon: require("../library/images/bing.png"),
      },
      "Yahoo Ads": {
        icon: require("../library/images/yahoo.png"),
      },
    },
    icon: "fa-photo",
    link: "paid media",
  },
  Video: {
    sub_offers: {
      Preroll: {
        icon: require("../library/images/preroll.png"),
      },
      Youtube: {
        icon: require("../library/images/youtube.png"),
      },
      // 'Tiktok': {
      //     'icon': require('../library/images/tiktok.png')
      // },
      "Video Ads": {
        icon: require("../library/images/video.png"),
      },
      "Ctv / Ott": {
        icon: require("../library/images/ctv.png"),
      },
    },
    icon: "fa-photo",
    link: "video",
  },
  SEO: {
    sub_offers: {},
    icon: "fa-file",
    link: "#",
  },
  "AUDIO ADS": {
    sub_offers: {},
    icon: "fa-volume-up",
    link: "#",
  },
  "PUSH ADS": {
    sub_offers: {},
    icon: "fa-adjust",
    link: "#",
  },
  OTHER: {
    sub_offers: {},
    icon: "fa-bars",
    link: "#",
  },
};

const PERMISSION_CODENAMES = {
  "CI-add":
    "competitive intelligence-create, destroy, update, retrieve, partial_update",
  "CI-view": "competitive intelligence-list, retrieve",
  "U-add": "users-create, update, partial_update",
  "U-delete": "users-destroy",
  "U-view": "users-list, retrieve, get_internal_members",
  "A-view": "analytics-retrieve, download",
  "R-view": "report-retrieve, download",
};

const BASE_PERM_CODENAMES = {
  "CI-add": "create, destroy, update, retrieve, partial_update",
  "CI-view":
    "list, retrieve,competitive_analytics_dashboard, make_model_distribution, competitor_prices",
  "U-add": "create, update, partial_update",
  "U-delete": "destroy",
  "U-view": "list, retrieve, get_internal_members",
  "A-view": "retrieve, download",
  "R-view": "retrieve, download",
};

const lowUsers = [3, 4];
const spendUsers = [2, 3, 4];

const SAVE_DEALER = "SAVE_DEALERS";
export {
  USER_MENU,
  OFFERINGS_MENU,
  PERMISSION_CODENAMES,
  BASE_PERM_CODENAMES,
  lowUsers,
  SAVE_DEALER,
  spendUsers,
};
