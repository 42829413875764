import React from "react";
import { Redirect, Route } from "react-router";
import { Layout } from "./layout";

function PrivateRoute({ component: Component, ...rest }) {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <Route
      {...rest}
      render={(props) =>
        user.user_level != 3 ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/product/dashboard",
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
