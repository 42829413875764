import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { USER_MENU } from "./constants";
import { logout } from "../apps/auth/actions";
import { requestPermLevel } from "../apps/user/apis";
import defaultCompanyLogo from "../asset/defaultImages/default-company.png";
import "../library/css/newStyles.css";
import wheelerAdvLogo from "../library/images/wheeler-adv-logo.png";
import profilePicDefault from "../library/images/profile-pic-default.png";
import { PERMISSION_CODENAMES } from "./constants";

// const API_URL = process.env.REACT_APP_API_URL;

class NavBar extends Component {
  addDefaultSrc = (event) => {
    event.target.src = defaultCompanyLogo;
  };
  permRequest = () => {
    requestPermLevel()
      .then(({ data }) => {
        swal("Success!", data.message, "success");
      })
      .catch(({ response }) => {
        swal("Sorry!", response.data.message, "error");
      });
  };
  render() {
    //console.log(this.props, localStorage.getItem("IP"));
    //console.log("permission1: ", PERMISSION_CODENAMES);
    const { user } = this.props;
    const { userType } = this.props;
    // const imgUrl = 'http://localhost:8000/media/images/6/business_youtube.png'
    // const imgUrl = `${API_URL}${user.product_logo}`
    const permissions = this.props.user.permissions
      .filter((i) => i.active)
      .map(({ codename, group }) => `${group.toLowerCase()}-${codename}`);
    // userType = 4 for internal members
    let menu = USER_MENU[userType];
    if (
      userType === 3 &&
      !permissions.includes(PERMISSION_CODENAMES["R-view"])
    ) {
      menu = menu.filter((m) => {
        if (m.label === "Reporting") {
          console.log("np reporitng permission");
          return m.label !== "Reporting";
        }
        return true;
      });
      console.log("menu ", menu);
    }

    if (
      userType === 3 &&
      !permissions.includes(PERMISSION_CODENAMES["CI-view"])
    ) {
      menu = menu.filter((m) => {
        if (m.label === "Competitive Intelligence") {
          console.log("np competitive intelligence permission");
          return m.label !== "Competitive Intelligence";
        }
        return true;
      });
      console.log("menu ", menu);
    }

    if (userType === 4) {
      menu = menu.filter((m) => {
        // if (m.label === 'Analytics') {
        //   return permissions.includes(PERMISSION_CODENAMES['A-view'])
        // }
        if (m.label === "Manage Users") {
          return permissions.includes(PERMISSION_CODENAMES["U-view"]);
        }
        // if (m.label === 'Reporting') {
        //   return permissions.includes(PERMISSION_CODENAMES['R-view'])
        // }
        return true;
      });
    }
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark bg-gradient mb-4">
          <Link className="navbar-brand" to="/product/dashboard/">
            <img
              src={wheelerAdvLogo}
              alt="Wheeler Advertisement logo"
              className="mw-100"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {menu.map((menu, index) => {
                return (
                  <li
                    key={`menu-item-${index}`}
                    className={`nav-item ${menu.subPaths ? "d-flex" : ""}`}
                  >
                    {userType === 1 && menu.label === "Reporting" ? null : (
                      <Link
                        to={menu.path}
                        key={`link-item-${index}`}
                        className="nav-link"
                      >
                        {menu.label}
                        {["Marketing"].includes(menu.label) ? (
                          <small>
                            (<i>Coming soon</i>)
                          </small>
                        ) : null}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
            <ul className="navbar-nav  ml-auto">
              <NavDropdown
                title={
                  <Fragment>
                    <span className="profile-ava">
                      {user.pro_pic ? (
                        <img alt="" src="/img/avatar1_small.jpg" />
                      ) : (
                        <img
                          src={profilePicDefault}
                          alt=""
                          className="nav-pro-pic"
                        />
                      )}
                    </span>
                    &nbsp;
                    <span className="username">{user.username}</span>
                    <b className="caret"></b>
                  </Fragment>
                }
                id="basic-nav-dropdown"
                className="mr-sm-4"
              >
                <NavDropdown.Item>
                  <Link to="/users/profile/">Profile</Link>
                </NavDropdown.Item>
                {this.props.userType === 3 && this.props.product_name === "" ? (
                  <NavDropdown.Item onClick={this.permRequest}>
                    Request Whitelabel
                  </NavDropdown.Item>
                ) : null}
                {this.props.userType === 2 ? (
                  <NavDropdown.Item>
                    <Link to="/product/settings/">Settings</Link>
                  </NavDropdown.Item>
                ) : null}
                {this.props.userType !== 1 ? (
                  <NavDropdown.Item>
                    <Link to="/product/support-form/">Support</Link>
                  </NavDropdown.Item>
                ) : null}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const dispatchStateToProps = (state) => {
  return {
    userType: state.authReducer.user.user_level,
    user: state.authReducer.user,
    product_name: state.authReducer.user.product_name,
    ip: state.authReducer.ip,
  };
};

export default connect(dispatchStateToProps, {})(NavBar);
