import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getMomentDate } from './index';



  // To get different dates
  const getDate = (timePeriod = "") =>{
    let dateObj = new Date();
    switch(timePeriod){
      case 'yesterday':                          
          return dateObj.setDate(dateObj.getDate() - 1);
      case 'start_this_month':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1)
      case 'end_this_month':
          return new Date(dateObj.getFullYear(), dateObj.getMonth() +1, 0);
      case 'start_last_month':
          return new Date(dateObj.getFullYear(), dateObj.getMonth() -1, 1);
      case 'end_last_month':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), 0);
      case 'last_sunday':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - dateObj.getDay());
      case 'last_monday':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - dateObj.getDay() + 1);
      case 'before_7_days':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 7);
      case 'before_14_days':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 14);
      case 'before_28_days':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 28);
      case 'before_30_days':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 30);
      case 'last_year_start':
          return new Date(dateObj.getFullYear() - 1, 0, 1);
      case 'last_year_end':
          return new Date(dateObj.getFullYear(), 0, 0);
      case 'this_year_start':
          return new Date(dateObj.getFullYear(), 0, 1);
      case 'this_year_end':
          return new Date(dateObj.getFullYear() + 1, 0, 0);
      case 'last_week_start':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - dateObj.getDay() - 6);
      case 'last_week_end':
          return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - dateObj.getDay());
      case 'this_quarter_start':
          let q = Math.ceil(dateObj.getMonth() / 3)
          return new Date(dateObj.getFullYear(),
                        q === 1 ? 0 : q === 2 ? 3 : q === 3 ? 6 : 9,
                        1)
      case 'last_quarter_start':
          let quarter = Math.ceil(dateObj.getMonth() / 3) - 1
          if(quarter === 0){
            quarter = 4;
          }
          return new Date(dateObj.getMonth() === 1||2||3 ? dateObj.getFullYear() -1 : dateObj.getFullYear(),
           quarter === 1 ? 0 : quarter === 2 ? 3 : quarter === 3 ? 6 : 9,
                          1)                  
      default:
          return new Date();
    }
  }

  
function ModuleDateRangePicker(props) {
  const { endDate, startDate, onDateChange, name } = props

  const initialSettings = props.offering === true ? 
  {
    showDropdowns: true,
    alwaysShowCalendars: true,
  }:
  {
    ranges: {
      // Today: [
      //   new Date(),
      //   new Date()
      // ],
      // Yesterday: [
      //   new Date(getDate('yesterday')),
      //   new Date(getDate('yesterday'))
      // ],
      // 'This Week to date (Starts Monday)': [
      //   new Date(getDate('last_monday')),
      //   new Date()
      // ],
      // 'This Week to date (Starts Sunday)': [
      //   new Date(getDate('last_sunday')),
      //   new Date()
      // ],
      // 'Last Week (Starts Monday)': [
      //   new Date(getDate('last_week_start')),
      //   new Date(getDate('last_week_end'))
      // ],
      // 'Last Week (Starts Sunday)': [
      //   new Date(getDate().getFullYear(), getDate().getMonth() , getDate('last_week_start').getDate() - 1),
      //   new Date(getDate().getFullYear(), getDate().getMonth() , getDate('last_week_end').getDate() - 1)
      // ],
      'Last 7 Days': [
        new Date(getDate('before_7_days')),
        new Date()
      ],
      // 'Last 14 Days': [
      //   new Date(getDate('before_14_days')),
      //   new Date()
      // ],
      // 'Last 28 Days': [
      //   new Date(getDate('before_28_days')),
      //   new Date()
      // ],
      // 'Last 30 Days': [
      //   new Date(getDate('before_30_days')),
      //   new Date()
      // ],
      // 'This Month': [
      //   new Date(getDate('start_this_month')),
      //   new Date(getDate('end_this_month'))
      // ],
      'Last Month': [
        new Date(getDate('start_last_month')),
        new Date(getDate('end_last_month'))
      ],
      // 'This Quarter': [
      //   new Date(getDate('this_quarter_start')),
      //   new Date(getDate('this_quarter_start').getFullYear(), getDate('this_quarter_start').getMonth() + 3, 0)
      // ],
      // 'Last Quarter': [
      //   new Date(getDate('last_quarter_start')),
      //   new Date(getDate('last_quarter_start').getFullYear(), getDate('last_quarter_start').getMonth() + 3, 0)
      // ],
      'This Year': [
        new Date(getDate('this_year_start')),
        new Date(getDate('this_year_end'))
      ],
      // 'Last year': [
      //   new Date(getDate('last_year_start')),
      //   new Date(getDate('last_year_end'))
      // ],
    },
    showDropdowns: true,
    alwaysShowCalendars: true,
  }

  
  if (startDate) initialSettings.startDate = startDate
  if (endDate) initialSettings.endDate = endDate
  return (
    <div>
      <DateRangePicker
        initialSettings={
          initialSettings
        }
        onApply={onDateChange}
        // disable={this.props.disable}
        // disableNavigation={true}
      >
        <div
          id={name}
          style={{
            background: '#fff',
            // border: '1px solid #ccc',
            cursor: 'pointer',
            padding: '5px 10px',
            width: '100%',
            // textAlign:"center"
          }}
        >
          <i className="fa fa-calendar mr-1" />
          {' '}
          <i className="fa fa-caret-down mr-1" />
          {startDate && endDate ? `${getMomentDate(startDate, 'YYYY-MM-DD')} - ${getMomentDate(endDate, 'YYYY-MM-DD')}` : null}
        </div>
      </DateRangePicker>
    </div>
  )
}

export  {ModuleDateRangePicker, getDate}


      // {{
      //   endDate,
      //   // ranges: {
      //   //   'Last 30 Days': [
      //   //     new Date('2020-06-29T13:27:21.521Z'),
      //   //     new Date('2020-07-28T13:27:21.521Z')
      //   //   ],
      //   //   'Last 7 Days': [
      //   //     new Date('2020-07-22T13:27:21.521Z'),
      //   //     new Date('2020-07-28T13:27:21.521Z')
      //   //   ],
      //   //   'Last Month': [
      //   //     new Date('2020-05-31T18:30:00.000Z'),
      //   //     new Date('2020-06-30T18:29:59.999Z')
      //   //   ],
      //   //   'This Month': [
      //   //     new Date('2020-06-30T18:30:00.000Z'),
      //   //     new Date('2020-07-31T18:29:59.999Z')
      //   //   ],
      //   //   Today: [
      //   //     new Date('2020-07-28T13:27:21.521Z'),
      //   //     new Date('2020-07-28T13:27:21.521Z')
      //   //   ],
      //   //   Yesterday: [
      //   //     new Date('2020-07-27T13:27:21.521Z'),
      //   //     new Date('2020-07-27T13:27:21.521Z')
      //   //   ]
      //   // },
      //   startDate
      // }}
