import { SAVE_DEALER } from "./constants"

const initialState = []

const DealerReducer = (state = initialState, action) => {
    switch(action.type){
        case SAVE_DEALER:
            return {
                dealerNames: [...action.payload]
            }
        default:
            return state
    }
}


export default DealerReducer