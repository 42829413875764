import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;

const USER_PROFILE_URL = `${API_URL}/core/userprofile/`
const USER_PERMISSIONS_URL = `${API_URL}/users/manage-permissions`
const CORE_URL = `${API_URL}/core`

export const getWhiteLabelUsers = (params) => {
  return axios.get(USER_PROFILE_URL, {
    params: params
  })
}

export const getInternalUsers = (params) => {
  return axios.get(`${USER_PROFILE_URL}internal-members/`, {
    params: params
  })
}

export const getWhiteLabelAdmins = (params) => {
  return axios.get(`${USER_PROFILE_URL}white-label-admins/`, {
    params: params
  })
}

export const changeActive = (id) => {
  return axios.post(`${USER_PROFILE_URL}${id}/change_status/`)
}

export const changePermLevel = (id) => {
  return axios.post(`${USER_PROFILE_URL}${id}/permission/approve/`)
}

export const requestPermLevel = () => {
  return axios.post(`${USER_PROFILE_URL}request/whitelabel/`)
}

export const getUser = id => {
  return axios.get(`${USER_PROFILE_URL}${id ? id : ''}`)
}

export const updateUserProfile = (id, values) => {
  return axios.patch(`${USER_PROFILE_URL}${id}/update_userprofile/`, values)
}

export const retrieveUser = (id) => {
  return axios.get(`${USER_PROFILE_URL}${id}/`)
}

export const updateUser = (values, id) => {
  return axios.put(`${USER_PROFILE_URL}${id}/`, values)
}

export const createUser = (values) => {
  return axios.post(`${USER_PROFILE_URL}`, values)
}

export const deleteUser = (id) => {
  return axios.delete(`${USER_PROFILE_URL}${id}/`)
}

export const updatePassword = (values) => {
  return axios.patch(`${USER_PROFILE_URL}change_password/`, values)
}

export const updateUserPassword = (values) => {
  return axios.patch(`${USER_PROFILE_URL}update/user/password/`, values)
}


// User Permissions API

export const getUserPermissions = (id, params) => {
  return axios.get(`${USER_PERMISSIONS_URL}/${id}/`, {
    params
  })
}

export const updatePermissions = (values, id) => {
  return axios.patch(`${USER_PERMISSIONS_URL}/${id}/`, values)
}

export const addPermissions = (values, params) => {
  return axios.post(`${USER_PERMISSIONS_URL}/`, values, {
    params
  })
}

export const deletePermissions = (data, id) => {
  return axios.delete(`${USER_PERMISSIONS_URL}/${id}/`, {
    data
  })
}


// Offerings

export const submitOffering = (values) => {
  return axios.post(`${CORE_URL}/submit-offering/`, values)
}
