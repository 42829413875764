import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import '../App.css';
import '../library/css/newStyles.css'
import NavBar from './NavBar'
// import SideBar from './SideBar'
import { getPermissions } from '../apps/auth/actions'
import { getDealers } from '../apps/product/apis';
import { dealerAction } from './actions';

class Layout extends Component {
  componentWillMount() {
    if(this.props.isAuthenticated === false)
      window.location = '/auth/login/'

    if (window.performance) {
      // getDealers({ group: 'Users' })
      // .then(({ data }) => {
      //   this.props.getDealers(data.result)
      // })
      // .catch(({ response }) =>{
      //   console.log(response)
      //   // swal("Warning!", "Dealers data not found", "warning")
      // })
      if (performance.navigation.type === 1 && [3, 4].includes(this.props.userType)) {
        this.props.getPermissions()
      }
    }
  }

  render() {
    return (
      <Fragment>
        {/* <!-- container section start --> */}
        <section id="container" className="">
          <NavBar />
          {/* <SideBar /> */}
          {/* <!--main content start--> */}
          <section id="main-content">
            {/* Children component here */}
            { this.props.children }
            {/* Children component END */}
          </section>
          {/* <!--main content end--> */}
        </section>
        {/* <!-- container section start --> */}
      </Fragment>
    );
  }
}

// Layout.propTypes = {
//   children: PropTypes.node,
// }

const mapDispatchToProps = dispatch => {
  return {
    getPermissions: (values) => {
      dispatch(getPermissions(values))
    },
    getDealers: (values) => {
      dispatch(dealerAction(values))
    }
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    userType: state.authReducer.user.user_level,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
