export const DEALER_NAMES = [
  "SouthWest Ford",
  "Windsor Nissan",
  "Link Ford Minong",
  "Link Ford Rice Lake",
  "Bowditch Ford",
  "Superior Acura",
  "Superior Acura - Dayton",
  "Superior Honda",
  "Superior Hyundai - Beavercreek",
  "Superior Hyundai North",
  "Superior Hyundai South",
  "Superior Kia",
  "Crestview RV",
  "Howdy RV",
  "Pierce RV",
  "RV Land TX",
  "Southern RV",
  "Woody's RV",
  "CCRV",
  "Bents RV",
  "Lewis RV Center",
  "Camperland RV",
  "Howard RV Center",
]
